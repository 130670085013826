<template>
  <b-modal
    id="preview-cms-article"
    title="Preview content"
    size="xl"
  >
    <div v-html="content" />
  </b-modal>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: () => '',
    },
  },
}
</script>
